<template>
	<div class="pagination-container">
		<div class="jumper-wrapper">
			<span> 到第 </span>
			<el-input
				v-model="pageInput"
				@keyup.enter.native="handleCurrentChange(pageInput)"
				@blur="handleCurrentChange(pageInput)"
				class="el-pagination__editor is-in-pagination"
			></el-input>
			<span> 页 </span>
		</div>

		<el-button
			icon="el-icon-back"
			plain
			class="btn-prev"
			:disabled="prevDisabled"
			@click="prev"
		></el-button>
		<el-pagination
			ref="pagination"
			layout="pager"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page.sync="currentPage"
			:page-size="pageSize"
			:total="+total"
			:pager-count="5"
		></el-pagination>
		<el-button icon="el-icon-right" plain class="btn-next" :disabled="nextDisabled" @click="next"></el-button>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		page: {
			type: Number,
			default: 1
		},
		total: {
			type: Number,
			required: true
		},
		pageSize: {
			type: Number,
			default: 10
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.mounted = true
	},
	created() {
		this.pageInput = this.currentPage
	},
	data() {
		return {
			mounted: false,
			pageInput: ''
		}
	},
	computed: {
		currentPage: {
			get() {
				return this.page
			},

			set(val) {
				this.$emit('update:page', val)
			}
		},
		pageCount() {
			if (!this.mounted) return 0
			return this.$refs.pagination.internalPageCount || 0
		},
		prevDisabled() {
			return this.disabled || this.currentPage <= 1
		},
		nextDisabled() {
			return this.disabled || this.currentPage === 0 || this.currentPage >= this.pageCount
		}
	},
	watch: {
		currentPage(val) {
			this.pageInput = val
		}
	},
	methods: {
		prev() {
			if (this.disabled) return
			this.currentPage -= 1

			this.$emit('currentChange')
		},
		next() {
			if (this.disabled) return
			this.currentPage += 1

			this.$emit('currentChange')
		},
		handleSizeChange(size) {
			this.$emit('sizeChange')
		},
		handleCurrentChange(page) {
			const p = page || this.currentPage || ''
			this.currentPage = this.$refs.pagination.getValidCurrentPage(p)

			this.$nextTick(() => {
				this.pageInput = this.currentPage
				// this.pageInput = ''
			})

			this.$emit('currentChange')
		}
	}
}
</script>

<style lang="scss">
$pagerWidth: 32px;

.pagination-container {
	display: flex;
}
.jumper-wrapper {
	display: flex;
	align-items: center;
	line-height: $pagerWidth;
	height: $pagerWidth;
	margin-right: 15px;
	font-size: 14px;

	.el-pagination__editor {
		padding: 0;
	}
	.el-input {
		// width: $pagerWidth;
		width: 50px;
		max-width: 100px;
		height: $pagerWidth;

		.el-input__inner {
			height: $pagerWidth;
			line-height: $pagerWidth;
			padding: 0 10px;
			text-align: center;
		}
	}
	span {
		padding: 0 5px;
	}
}
.btn-prev,
.btn-next {
	padding: 0 6px;
	width: $pagerWidth;
	border: 1px solid rgba(0, 0, 0, .08);

	i {
		font-weight: bold;
	}
}
.btn-prev,
.btn-next {
	&:hover {
		border-color: rgba(0, 0, 0, .08) !important;
	}
	&:focus {
		border-color: rgba(0, 0, 0, .08) !important;
	}
}
.btn-prev {
	border-radius: 5px 0 0 5px;
}
.btn-next {
	border-radius: 0 5px 5px 0;
}
.el-pagination {
	height: $pagerWidth;
	line-height: $pagerWidth;
	padding: 0;

	.el-pagination__jump,
	.btn-prev,
	.btn-next,
	.el-pager {
		height: $pagerWidth;
		line-height: $pagerWidth;
	}
	.el-pager {
		li {
			width: $pagerWidth;
			height: $pagerWidth;
			line-height: $pagerWidth;
			border: 1px solid rgba(0, 0, 0, .08);
			border-right: none;
			color: rgba(0, 0, 0, .6);

			&:first-of-type {
				border-left: none;
			}
			&.active {
				background-color: #147ef9;
				color: #fff;
			}
		}
	}
}
</style>
