<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="660px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="right" ref="dataForm">
			<el-form-item label="课程编号" prop="code">
				<el-input size="small" v-model="dataForm.code" placeholder="课程编号" maxlength="100" :disabled="disable"></el-input>
			</el-form-item>
			<div class="flex">
				<el-form-item label="课程名称" prop="name" class="flex-1 mr10">
					<el-input size="small" v-if="nameflag == '2'" v-model="dataForm.name" placeholder="课程名称"
					maxlength="100" :disabled="disable" clearable></el-input>
					<el-cascader
						v-if="nameflag == '1'"
						:options="modelDevices"
						:props="machineProps"
						placeholder="课程名称"
						ref="myCascader"
						v-model="nameId"
						clearable
						filterable
						:disabled="disable"
						@change="deviceCascaderVisableChange"
						:show-all-levels="false">
					</el-cascader>
				</el-form-item>
				<button type="button" class="btn1" v-if="nameflag == '2'" @click="handleName('1')">选择</button>
				<button type="button" class="btn1" v-if="nameflag == '1'" @click="handleName('2')">手输</button>
			</div>
			<div class="flex">
				<el-form-item label="课程类型" prop="courseType" class="flex-1 mr10">
					<el-select v-model="dataForm.courseType" filterable placeholder="请选择课程类型" @change="handleCourseType" :disabled="disable" class="select">
						<el-option
							v-for="option in modelDevices"
							:key="option.id"
							:label="option.name"
							:value="option.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="教育分类" prop="eduType" class="flex-1">
					<el-select size="small" v-model="dataForm.eduType" filterable placeholder="教育分类" :disabled="disable">
						<el-option label="远程" value="1"></el-option>
						<el-option label="集合" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="有效学习天数" prop="validateDates" class="flex-1 mr10">
					<el-input size="small" v-model="dataForm.validateDates" placeholder="有效学习天数" maxlength="3" :disabled="disable"></el-input>
				</el-form-item>
				<el-form-item label="课程权重" prop="courseWeight" class="flex-1">
					<el-input size="small" v-model="dataForm.courseWeight" placeholder="课程权重" maxlength="3" :disabled="disable"></el-input>
				</el-form-item>
			</div>
			<div class="flex" v-if="dataForm.eduType == '1'">
				<el-form-item label="考试时间" prop="examinTime" class="flex-1 mr10">
					<el-input size="small" v-model="dataForm.examinTime" placeholder="考试时间(分钟)" maxlength="3" :disabled="disable"></el-input>
				</el-form-item>
				<el-form-item label="及格分数" prop="passScore" class="flex-1">
					<el-input size="small" v-model="dataForm.passScore" placeholder="及格分数" maxlength="3" :disabled="disable"></el-input>
				</el-form-item>
			</div>
			<div class="flex" v-if="dataForm.eduType == '1'">
				<el-form-item label="随机考试题目数" prop="examinAmount" class="flex-1 mr10">
					<el-select size="small" v-model="dataForm.examinAmount" filterable placeholder="随机考试题目数" :disabled="disable">
						<el-option label="20" value="20"></el-option>
						<el-option label="25" value="25"></el-option>
						<el-option label="50" value="50"></el-option>
						<el-option label="100" value="100"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="可考试次数" prop="examinLimiteTimes" class="flex-1">
					<el-input size="small" v-model="dataForm.examinLimiteTimes" placeholder="可考试次数" maxlength="3" :disabled="disable"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="附件" v-if="dataForm.eduType == '1' && scheduleFlag">
				<div class="upload-wrapper">
					<el-upload
						ref="upload"
						drag
						:disabled="disable"
						:limit='1'
						:action="action"
						:headers="headers"
						:file-list="files"
						:on-exceed="handleExceed"
						:before-upload="handleBeforeUpload"
						:on-success="handleSuccess"
						:on-error="handleError"
						:on-remove="handleRemove">
						<div class="upload-info">
							<div class="upload-image mr8">
								<img src="@/assets/images/shangchuan.png"/>
							</div>
							<div class="upload-tip">
								<div class="message info-text"> 点击或将文件拖拽到这里上传 </div>
								<div class="tip"> 仅支持扩展名: .pdf </div>
							</div>
						</div>
					</el-upload>
				</div>
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input size="small"
					type="textarea"
					:rows="5"
					v-model="dataForm.remark"
					placeholder="备注"
					maxlength="500"
					:disabled="disable">
				</el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer" v-if="!disable">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import md5 from 'blueimp-md5'
const defaultSettings = require('@/settings.js')
const url = defaultSettings.hostName
export default {
	data() {
		return {
			title: '新增课程',
			visible: false,
			disable: false,
			submitUrl: 'addCourse',
			nameflag: '1',//1选择2手输
			nameId: '',
			modelDevices: [],
			machineOptions: [],
			machineProps: {
				value: 'id',
				label: 'name',
				children: 'children',
				disabled: 'disabled'
			},
			files: [],
			fileLists: [],
			fileIds: [],
			dataForm: {
				code: '',
				name: '',
				nameId: '',
				courseType: '',
				courseTypeNames: '',
				eduType: '',
				validateDates: '',
				courseWeight: '',
				examinTime: '',
				examinAmount: '',
				examinLimiteTimes: '',
				passScore: '',
				remark: '',
				fileIdList: []
			},
			dataRule: {
				code: [
					{required: true,message: '课程编号不能为空', trigger: "blur"},
				],
				name: [
					{required: true,message: '课程名称不能为空', trigger: "blur"},
				],
				courseType: [
					{required: true,message: '请选择课程类型', trigger: "change"},
				],
				eduType: [
					{required: true,message: '请选择教育分类', trigger: "change"},
				],
				validateDates: [
					{required: true,message: '有效学习天数不能为空', trigger: "blur"},
					{pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'}
				],
				courseWeight: [
					{pattern: /^[0-9]\d*$/, message: '请输入大于等于0的正整数'}
				],
				examinTime: [],
				examinLimiteTimes: [],
				examinAmount: [],
				passScore: []
			},
			// 培训日程表不显示附件
			scheduleFlag: true
		}
	},
	computed: {
		action() {
			return url + "course/courseInfo/addFile"
		},
		headers() {
			const token = sessionStorage.getItem('token') || ''

			return {
				token: token
			}
		},
		// 是否是超级管理员
		isSuperAdmin() {
			const userInfoStr = window.sessionStorage.getItem('userInfo')
			if (!userInfoStr) return false

			const userInfo = JSON.parse(userInfoStr)
			if (!userInfo) return false
			if (userInfo.superAdminFlag === '1') return true

			return false
		},
	},
	watch: {
		'dataForm.eduType'(dt){
			// 远程
			if(dt == '1'){
				this.dataRule.examinTime.push({required: true,message: '考试时间不能为空', trigger: "blur"});
				this.dataRule.examinTime.push({pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'});
				this.dataRule.examinLimiteTimes.push({required: true,message: '可考试次数不能为空', trigger: "blur"});
				this.dataRule.examinLimiteTimes.push({pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'});
				this.dataRule.examinAmount.push({required: true,message: '请选择随机考试题目数', trigger: "change"});
				this.dataRule.passScore.push({required: true,message: '及格分数不能为空', trigger: "blur"});
				this.dataRule.passScore.push({pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'});
			}
			// 集合
			else {
				this.dataRule.examinTime = [];
				this.dataRule.examinLimiteTimes = [];
				this.dataRule.examinAmount = [];
				this.dataRule.passScore = [];
			}
		}
	},
	methods: {
		init(type,data) {
			console.log(data)
			this.visible = true;
			// 获取所有机种分类数据
			this.getModelDevice();
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.nameId = '';
				this.files = [];
				this.fileIds = [];
				this.fileLists = [];
				this.dataForm.examinTime = '';
				this.dataForm.examinLimiteTimes = '';
				this.dataForm.examinAmount = '';
				this.dataForm.passScore = '';
				this.scheduleFlag = data.page == 'schedule' ? false : true;
				if(type == 'add') {
					this.title = '新增课程';
					delete this.dataForm.id;
					this.submitUrl = 'addCourse';
					this.disable = false;
				}
				else if(type == 'edit') {
					this.title = '修改课程';
					this.submitUrl = 'courseUpdate';
					this.disable = false;
					this.getCourseInfo(data.id);
				}
				else {
					this.title = '查看课程';
					this.submitUrl = '';
					this.disable = true;
					this.getCourseInfo(data.id);
				}
			})
		},
		// 获取所有机种分类数据
		getModelDevice() {
			let that = this
			that.$request.post(
				"sysModelDevice",
				true, {},
				function (r) {
					if (r.code == "0") {
						that.modelDevices = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			)
		},
		// 课程名称手输/选择
		handleName(type) {
			if(this.title == '查看课程'){
				return
			}
			this.dataForm.nameId = '';
			if(type == '1') {
				this.nameflag = '1';
			}else {
				this.nameflag = '2';
				// this.title == '修改课程' ? this.dataForm.nameId = '' : '';
			}
		},
		// 课程名称
		deviceCascaderVisableChange(value) {
			this.dataForm.name = this.$refs['myCascader'].getCheckedNodes()[0].label;
			this.dataForm.nameId = this.$refs['myCascader'].getCheckedNodes()[0].value;
		},
		// 课程分类
		handleCourseType(val) {
			this.dataForm.courseTypeNames = val ? this.modelDevices.find(ele => ele.id === val).name: '';
		},
		handleExceed() {
			this.$message.warning('仅支持上传一个pdf文件,请删除下面的pdf再进行上传');
		},
		// 上传之前
		handleBeforeUpload(file) {
			return new Promise((resolve, reject) => {
				if(this.dataForm.fileIdList.length > 0) {
					this.$message.warning('仅支持上传一个pdf文件');
					return reject(false)
				}
				if(file.type != 'application/pdf') {
					this.$message.warning('仅支持pdf文件上传');
					return reject(false)
				}
				if (this.isSuperAdmin) {
					if(file.size / 1024 / 1024 / 1024 > 1) {
						this.$message.warning('文件大小不能超过1G');
						return reject(false)
					}
				} else {
					// 2022-07-31 应客户要求改为200M
					if(file.size / 1024 / 1024 > 200) {
						this.$message.warning('文件大小不能超过200M');
						return reject(false)
					}
				}
				resolve();
			})
		},
		handleSuccess(response, file, fileList) {
			this.dataForm.fileIdList = [];
			response.data.forEach(item => {
				this.dataForm.fileIdList.push(item.id);
			})
		},
		handleError(err, file, fileList) {
			console.log(err)
			console.log(file)
			console.log(fileList)
		},
		handleRemove(file, fileList) {
			this.dataForm.fileIdList = [];
		},
		// 获取课程信息
		getCourseInfo(id) {
			let that = this
			that.$request.post(
				that.scheduleFlag ? "courseInfo" : "dayCourseInfo",
				true, {
					id: id
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.id = r.data.id;
						that.dataForm.code = r.data.code;
						that.dataForm.name = r.data.name;
						that.nameId = r.data.nameId+'';
						that.dataForm.nameId = r.data.nameId;
						that.nameflag = r.data.nameId ? '1' : '2';
						that.dataForm.courseType = r.data.courseType+'';
						that.dataForm.courseTypeNames = r.data.courseTypeNames;
						that.dataForm.eduType = r.data.eduType+'';
						that.dataForm.validateDates = r.data.validateDates;
						that.dataForm.courseWeight = r.data.courseWeight;
						that.dataForm.examinTime = r.data.examinTime;
						that.dataForm.passScore = r.data.passScore;
						that.dataForm.examinAmount = r.data.examinAmount;
						that.dataForm.remark = r.data.remark;
						that.dataForm.examinLimiteTimes = r.data.examinLimiteTimes;
						that.dataForm.fileIdList = [];
						r.data.sysAttachFileList.forEach(item => {
							item.name = item.fileName;
							that.fileIds.push(item.id);
							that.dataForm.fileIdList.push(item.id);
							that.fileLists.push(item);
						})
						that.files = that.fileLists.length > 0 ? [that.fileLists[0]] : [];
					} else {
						that.$message.error(r.msg);
					}
				}
			)
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let submitForm = this.dataForm;
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getCourseList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 20px;
	.el-select,
	.el-cascader{
		width: 100%;
	}
	.select /deep/ .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
	.el-cascader{
		line-height: 32px;
		/deep/ .el-input__inner{
			height: 32px;
		}
	}
}
.submit-btn{
	display: block;
	width: 60%;
	margin: 0 auto;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
/deep/ .el-upload {
	width: 100%;

	.el-upload-dragger {
		width: 100%;
	}
}
/deep/ .el-upload-dragger {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
}
.btn1{
	width: 60px;
	height: 32px;
	text-align: center;
	color: #000;
	background-color: #fff;
	outline: none;
	border: 1px solid #ddd;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 27px;
}

</style>
